<template>
  <div>
    <div class="material center-noflex">
      <div class="material-title">IP素材</div>
      <div class="font-first">汇聚国内优质文化IP版权提供IP交易与授权</div>
      <div
        class="align-center"
        style="transform: translateX(16%); margin-top: 20px"
      >
        <div class="between scarch">
          <div class="scarch-box">
            <input v-model="SeachContent" type="text" />
          </div>
          <div class="sracher-button" @click="SeachTab">
            <img src="" alt="" />
            查询
          </div>
        </div>
        <div class="flex">
          <div class="GoSettle" @click="Goservice_add">申请入驻</div>
        </div>
      </div>
    </div>
    <div class="material-tab center-noflex">
      <!-- <div>
        <img :src="banner_img" alt="" />
      </div> -->
      <div class="material-banner">
        <el-carousel height="400px" style="overflow: hidden">
          <el-carousel-item v-for="(item, index) in bannerList" :key="index">
            <div class="material-banner-img center" @click="IPURL(item)">
              <img :src="item.image" alt="" />
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="center tab-list">
        <div
          class="tab-item"
          :class="{ selectTab_item: SelectTab == 1 }"
          @click="tabsimg(1)"
        >
          推荐
        </div>
        <div
          class="tab-item"
          :class="{ selectTab_item: SelectTab == 2 }"
          @click="tabsimg(2)"
        >
          最新发布
        </div>
        <div
          class="tab-item"
          :class="{ selectTab_item: SelectTab == 3 }"
          @click="tabsimg(3)"
        >
          全部素材
        </div>
      </div>
    </div>
    <div class="material-tab-footer center-noflex">
      <div class="material-list">
        <div
          v-for="(item, index) in list"
          :key="index"
          class="material-item"
          @click="Godetali(item, index)"
        >
          <img :src="item.image" alt="" class="banerimage" />
          <div>
            <div class="material-item-title">{{ item.title }}</div>
            <div class="flex" style="margin-left: 10px">
              <div
                class="material-item-tag"
                v-for="(items, indexs) in item.tag_title"
                :key="indexs"
              >
                {{ items }}
              </div>
            </div>
            <div
              class="flex material-Icon end"
              style="font-size: 12px; color: #c7c7c7"
            >
              <div class="align-center" style="margin-right: 5px">
                <img
                  style="height: 13px; width: 18px; margin-right: 5px"
                  src="@/assets/material/Look_Icon.png"
                  alt=""
                />
                <div>{{ item.is_look }}</div>
              </div>
              <div class="align-center">
                <img
                  style="height: 14px; width: 15px; margin-right: 5px"
                  src="@/assets/material/like_Icon.png"
                  alt=""
                />
                <div>{{ item.is_dz }}</div>
              </div>
            </div>
            <div class="align-center item-footer">
              <img :src="item.image" alt="" />
              <div>{{ item.power }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="materrial-pagination">
        <!-- <el-pagination background layout="prev, pager, next" :total="500">
        </el-pagination> -->
        <div class="all-gengduo center" @click="Goall">更多 ></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "material",
  async created() {
    const { data } = await this.$api({
      c: "index",
      a: "IP_sucai",
      type: "1",
    });
    this.list = data.arr;
    this.bannerList = data.banner;
    // //console.log(this.list);
  },
  data() {
    return {
      bannerList: [],
      SeachContent: "",
      SelectTab: 1,
      list: [
        // {
        //   img: require("@/assets/test/Test_Icon2.png"),
        //   headerImg: require("@/assets/test/Test_Icon3.png"),
        //   title: "刘备彩色版",
        //   name: "所属方名称",
        //   tag: ["漫画作品", "影视作品"],
        //   Look: "365",
        //   like: "684",
        // },
      ],
    };
  },
  methods: {
    async SeachTab() {
      const { data } = await this.$api({
        c: "index",
        a: "IP_sucai",
        seach_title: this.SeachContent,
      });
      this.list = data.arr;
      this.SeachContent = "";
    },
	IPURL(e){
		//console.log(e.link);
		if(e.link){
			var links = e.link.split("#");
			//console.log(links[1]);
			this.$router.push(links[1]);
		}
		
	},
    Goall() {
      this.$router.push("/index/Materialall");
    },
    async tabsimg(e) {
      const { data } = await this.$api({
        c: "index",
        a: "IP_sucai",
        type: e,
      });
      this.SelectTab = e;
      this.list = data.arr;
    },

    Godetali(e) {
      this.$router.push({
        path: "/index/Materialdetalis",
        query: {
          // imgUrl: e.image,
          // title: e.title,
          id: e.id,
        },
      });
    },
    Goservice_add() {
      this.$router.push("/index/settle");
    },
  },
};
</script>
<style lang="scss">
.material-banner {
  width: 100%;

  .el-carousel__arrow {
    display: none !important;
  }
}
</style>
<style lang="scss" scoped>
.material {
  background: url("../../assets/material/material.png");
  background-size: 100% 100%;
  height: 500px;
  color: #ffffff;

  .material-title {
    font-weight: 900;
    font-size: 34px;
  }
}

.scarch {
  width: 400px;

  border-radius: 100px;
  border: 1px solid #ffffff;
  padding-left: 10px;

  .scarch-box {
    flex: 1;
    padding-right: 10px;
  }

  input {
    width: 100%;
    height: 100%;
    padding: 2px 0;
  }
}

.sracher-button {
  position: relative;
  right: -1px;
  padding: 7px 28px;
  background-color: #ffffff;
  border-radius: 00px 100px 100px 0px;
  color: rgb(0, 118, 254);
}

.GoSettle {
  border-radius: 100px 100px 100px 0px;
  background-color: rgb(253, 71, 1);
  padding: 7px 16px;
  margin-left: 20px;
}

.material-tab {
  padding: 20px;
}

.material-tab {
  margin-top: 40px;
}

.tab-item {
  width: 100px;
  height: 50px;
  line-height: 50px;
  border-bottom: 2px solid #ffffff;
  text-align: center;
}

.selectTab_item {
  border-bottom: 2px solid rgb(0, 118, 254);
}

.material-list {
  width: 1200px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  // display: grid;
  // grid-template-columns: 283px 283px 283px 283px;
  // grid-column-gap: 23px;
}

.material-tab-footer {
  background-color: #f8f8f8;
}

.material-item-tag {
  padding: 0px 4px;
  margin-right: 4px;
  border-radius: 50px;
  font-size: 10px;
  border: 1px solid rgb(0, 118, 254);
  color: rgb(0, 118, 254);
}

.material-Icon {
  margin-top: 28px;
  padding-bottom: 16px;
  border-bottom: 1px solid #ececec;
}

.material-item-title {
  font-weight: 100;
  font-size: 20px;
  padding-left: 20px;
  margin-bottom: 5px;
}

.material-item {
  width: 283px;
  background-color: #fff;
  border-bottom: 2px solid #ffffff;
  transition: all 0.8s;
  margin-bottom: 23px;

  .banerimage {
    object-fit: cover;
    width: 100%;
    height: 300px;
  }

  &:hover {
    border-bottom: 2px solid rgb(0, 118, 254);
  }

  .item-footer {
    color: #676767;
    font-size: 12px;

    padding: 14px 12px;

    img {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      margin-right: 5px;
    }
  }
}

.materrial-pagination {
  margin: 43px 0 50px;
  display: flex;
  justify-content: center;

  .all-gengduo {
    width: 122px;
    border-radius: 100px;
    border: 1px solid rgb(0, 118, 254);
    color: rgb(0, 118, 254);
    text-align: center;
    padding: 5px 0px;
  }
}
</style>
